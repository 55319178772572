// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-js": () => import("./../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-publications-js": () => import("./../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-support-js": () => import("./../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-team-app-js": () => import("./../src/pages/team/app.js" /* webpackChunkName: "component---src-pages-team-app-js" */),
  "component---src-pages-team-architecture-js": () => import("./../src/pages/team/architecture.js" /* webpackChunkName: "component---src-pages-team-architecture-js" */),
  "component---src-pages-team-js": () => import("./../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-team-localization-js": () => import("./../src/pages/team/localization.js" /* webpackChunkName: "component---src-pages-team-localization-js" */),
  "component---src-pages-team-navigation-js": () => import("./../src/pages/team/navigation.js" /* webpackChunkName: "component---src-pages-team-navigation-js" */)
}

